import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"
import posed from "react-pose"
import { motion } from "framer-motion"
import { TransitionState } from "gatsby-plugin-transition-link"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
})

const sequenceFade = {
  hidden: {
    opacity: 0,
    y: "10px",
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      delay: i * 0.3,
      ease: "easeInOut",
    },
  }),
}

const TextPage = props => {
  // console.log('TextPage props:', props.data)
  const metadata = props.data.textpage.metadata
  const date = props.data.prismicTextPage.last_publication_date
  const { last_updated, title, copy } = props.data.prismicTextPage.data

  // console.log('date: ', date)

  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={
                metadata.metadata_image.localFile.childImageSharp.fixed.src
              }
              pathname={props.location.pathname}
              origin={props.location.origin}
              noindex={true}
            />
            <div className="text-page wrapper is-fluid">
              <div className="columns">
                <div className="column is-10-tablet is-8-desktop is-half-widescreen is-offset-1-tablet is-offset-2-desktop is-offset-3-widescreen">
                  <div className="text-page__content content">
                    <motion.h1
                      className="title is-size-3 is-size-2-fullhd has-text-centered"
                      custom="1"
                      initial="hidden"
                      animate="visible"
                      variants={sequenceFade}
                    >
                      {title.text}
                    </motion.h1>
                    <div className="content">
                      <motion.div
                        className="text-page__date is-size-5-mobile is-size-4 has-text-centered"
                        custom="2"
                        initial="hidden"
                        animate="visible"
                        variants={sequenceFade}
                      >
                        {last_updated}&nbsp;
                        {<Moment format="YYYY/MM/DD" date={date} />}
                      </motion.div>
                      <motion.div
                        className="text-page__copy"
                        custom="3"
                        initial="hidden"
                        animate="visible"
                        variants={sequenceFade}
                      >
                        <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const getData = graphql`
  query TextPageyQuery($id: String!, $uid: String!, $lang: String!) {
    textpage: prismicTextPageBodyMetadata(id: { eq: $id }) {
      metadata: primary {
        metatitle
        description
        keywords
        metadata_image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    prismicTextPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      last_publication_date
      data {
        last_updated
        title {
          text
        }
        copy {
          html
        }
      }
    }
  }
`
export default TextPage
